/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    play: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.804 8L5 4.633v6.734zm.792-.696a.802.802 0 010 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"/>',
    },
});
